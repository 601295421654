import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-arts',
  templateUrl: './arts.component.html',
  styleUrls: ['./arts.component.css']
})
export class ArtsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
