import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-horrorfps',
  templateUrl: './horrorfps.component.html',
  styleUrls: ['./horrorfps.component.css']
})
export class HorrorfpsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
