import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { ModalModule } from 'ngx-bootstrap/modal';
import { ResumeComponent } from './resume/resume.component';
import { ContactComponent } from './contact/contact.component';
import { HomeComponent } from './home/home.component';
import { ModelsComponent } from './models/models.component';
import { ArtsComponent } from './arts/arts.component';
import { StudyComponent } from './study/study.component';
import { PeckboyComponent } from './peckboy/peckboy.component';
import { ColorsattackComponent } from './colorsattack/colorsattack.component';
import { PersonalComponent } from './personal/personal.component';
import { DonkeykongfourComponent } from './donkeykongfour/donkeykongfour.component';
import { MonsternightComponent } from './monsternight/monsternight.component';
import { JumpervrComponent } from './jumpervr/jumpervr.component';
import { GamesfortwoComponent } from './gamesfortwo/gamesfortwo.component';
import { HorrorfpsComponent } from './horrorfps/horrorfps.component';
import { DonkeykongforeverComponent } from './donkeykongforever/donkeykongforever.component';

@NgModule({
  declarations: [
    AppComponent,
    ResumeComponent,
    ContactComponent,
    HomeComponent,
    ModelsComponent,
    ArtsComponent,
    StudyComponent,
    PeckboyComponent,
    ColorsattackComponent,
    PersonalComponent,
    DonkeykongfourComponent,
    MonsternightComponent,
    JumpervrComponent,
    GamesfortwoComponent,
    HorrorfpsComponent,
    DonkeykongforeverComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,   
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
