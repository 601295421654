import { Component, OnInit } from '@angular/core';
import baguetteBox from 'baguettebox.js';

@Component({
  selector: 'app-peckboy',
  templateUrl: './peckboy.component.html',
  styleUrls: ['./peckboy.component.css']
})
export class PeckboyComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    baguetteBox.run('.gallery');
  }

}
