import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ResumeComponent } from './resume/resume.component';
import { ContactComponent } from './contact/contact.component';
import { HomeComponent } from './home/home.component';
import { ModelsComponent } from './models/models.component';
import { ArtsComponent } from './arts/arts.component';
import { StudyComponent } from './study/study.component';
import { PeckboyComponent } from './peckboy/peckboy.component';
import { ColorsattackComponent } from './colorsattack/colorsattack.component';
import { PersonalComponent } from './personal/personal.component';
import { DonkeykongfourComponent } from './donkeykongfour/donkeykongfour.component';
import { MonsternightComponent } from './monsternight/monsternight.component';
import { JumpervrComponent } from './jumpervr/jumpervr.component';
import { GamesfortwoComponent } from './gamesfortwo/gamesfortwo.component';
import { HorrorfpsComponent } from './horrorfps/horrorfps.component';
import { DonkeykongforeverComponent } from './donkeykongforever/donkeykongforever.component';

const routes: Routes = [
  { path: 'resume', component: ResumeComponent },
  { path: 'contact', component: ContactComponent },
  { path: 'home', component: HomeComponent },
  { path: 'models', component: ModelsComponent },
  { path: 'arts', component: ArtsComponent },
  { path: 'study', component: StudyComponent },
  { path: 'peckboy', component: PeckboyComponent },
  { path: 'colorsattack', component: ColorsattackComponent },
  { path: 'personal', component: PersonalComponent },
  { path: 'donkeykongfour', component: DonkeykongfourComponent },
  { path: 'monsternight', component: MonsternightComponent },
  { path: 'jumpervr', component: JumpervrComponent },
  { path: 'gamesfortwo', component: GamesfortwoComponent },
  { path: 'horrorfps', component: HorrorfpsComponent },
  { path: 'donkeykongforever', component: DonkeykongforeverComponent },
  { path: '', redirectTo: '/home', pathMatch: 'full' },
];


@NgModule({
  imports: [RouterModule.forRoot(routes , { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
