import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-gamesfortwo',
  templateUrl: './gamesfortwo.component.html',
  styleUrls: ['./gamesfortwo.component.css']
})
export class GamesfortwoComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
