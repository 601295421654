import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-models',
  templateUrl: './models.component.html',
  styleUrls: ['./models.component.css']
})
export class ModelsComponent implements OnInit {

  public currentPage: number;
  public totalPages: number = 3;

  constructor() { }

  ngOnInit() {
    this.currentPage = 1;
  }

  changePage(val){
    this.currentPage = val;
  }

}
