import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-colorsattack',
  templateUrl: './colorsattack.component.html',
  styleUrls: ['./colorsattack.component.css']
})
export class ColorsattackComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
